body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  font-family: 'Pretendard', sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, select, textarea{outline:none !important;}

input:focus::placeholder {
  color: transparent;
}

* {margin:0; padding:0; box-sizing:border-box;}
html {-webkit-text-size-adjust:none;-moz-text-size-adjust:none;-ms-text-size-adjust:none;text-size-adjust:none;}
ul,ol,dl,dt,dd {list-style:none; margin:0; padding:0;}
img {max-width:100%; height:auto;}
h1, h2, h3, h4, h5, h6, p {margin:0;}
ul,ol,dl {list-style:none;margin-bottom:0;}
label {font-weight:normal;}
p {margin:0;}
address {margin:0;}
.small, small {font-weight:inherit;}
button{outline:none !important; box-shadow:none !important; -webkit-appearance:none; -moz-appearance:none; appearance:none; border:0;}

/* LINK */
a,a:hover,a:focus,a:visited {color:inherit; text-decoration:none; outline:0;}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,footer, header, hgroup, menu, nav, section{display:block;}
.clear::after{content:''; clear:both; display:block;}

/* keyframes */
@keyframes join_button_toast_desktop {
  0%{
    transform: translate3d(0px, -30px, 0px);
  }
  8%{
    transform: translate3d(0px, -100px, 0px);
  }
  90%{
    transform: translate3d(0px, -100px, 0px);
    opacity: 1;
  }
  100%{
    transform: translate3d(0px, -30px, 0px);
    opacity: 0;
  }
}

@keyframes join_button_toast_mobile {
  0%{
    transform: translate3d(0px, -30px, 0px);
  }
  8%{
    transform: translate3d(0px, -100px, 0px);
  }
  90%{
    transform: translate3d(0px, -100px, 0px);
    opacity: 1;
  }
  100%{
    transform: translate3d(0px, -30px, 0px);
    opacity: 0;
  }
}

@keyframes join_input_toast_desktop {
  0%{
    transform: translate3d(0px, 0px, 0px);
  }
  8%{
    transform: translate3d(0px, -70px, 0px);
  }
  90%{
    transform: translate3d(0px, -70px, 0px);
    opacity: 1;
  }
  100%{
    transform: translate3d(0px, 0px, 0px);
    opacity: 0;
  }
}

@keyframes join_input_toast_mobile {
  0%{
    transform: translate3d(0px, -170px, 0px);
  }
  8%{
    transform: translate3d(0px, -200px, 0px);
  }
  90%{
    transform: translate3d(0px, -200px, 0px);
    opacity: 1;
  }
  100%{
    transform: translate3d(0px, -170px, 0px);
    opacity: 0;
  }
}


@keyframes authentication_modal_toast_desktop {
  0%{
    transform: translate3d(0px, 0px, 0px);
  }
  8%{
    transform: translate3d(0px, -70px, 0px);
  }
  90%{
    transform: translate3d(0px, -70px, 0px);
    opacity: 1;
  }
  100%{
    transform: translate3d(0px, 0px, 0px);
    opacity: 0;
  }
}

@keyframes authentication_modal_toast_mobile {
  0%{
    transform: translate3d(0px, 0px, 0px);
  }
  8%{
    transform: translate3d(0px, -70px, 0px);
  }
  90%{
    transform: translate3d(0px, -70px, 0px);
    opacity: 1;
  }
  100%{
    transform: translate3d(0px, 0px, 0px);
    opacity: 0;
  }
}

@keyframes toast_desktop {
  0%{
    transform: translate3d(0px, 60px, 0px);
  }
  8%{
    transform: translate3d(0px, -30px, 0px);
  }
  90%{
    transform: translate3d(0px, -30px, 0px);
    opacity: 1;
  }
  100%{
    transform: translate3d(0px, 60px, 0px);
    opacity: 0;
  }
}

@keyframes toast_mobile {
  0%{
    transform: translate3d(0px, 0px, 0px);
  }
  8%{
    transform: translate3d(0px, -90px, 0px);
  }
  90%{
    transform: translate3d(0px, -90px, 0px);
    opacity: 1;
  }
  100%{
    transform: translate3d(0px, 0px, 0px);
    opacity: 0;
  }
}

@keyframes indicator_updown {
  0%{
      transform: translate3d(0px, 0px, 0px);
  }
  100%{
      transform: translate3d(0px, -5px, 0px);
  }
}

/* Loader */
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@-webkit-keyframes load6 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #4480F7, 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.5), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.7), 1.8em -1.8em 0 0em #4480F7, 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.5), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.7), 2.5em 0em 0 0em #4480F7, 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.5), 2.5em 0em 0 0em rgba(68, 128, 247, 0.7), 1.75em 1.75em 0 0em #4480F7, 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.5), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.7), 0em 2.5em 0 0em #4480F7, -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.5), 0em 2.5em 0 0em rgba(68, 128, 247, 0.7), -1.8em 1.8em 0 0em #4480F7, -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.5), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.7), -2.6em 0em 0 0em #4480F7, -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.5), -2.6em 0em 0 0em rgba(68, 128, 247, 0.7), -1.8em -1.8em 0 0em #4480F7;
  }
}
@keyframes load6 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #4480F7, 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.5), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.7), 1.8em -1.8em 0 0em #4480F7, 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.5), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.7), 2.5em 0em 0 0em #4480F7, 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.5), 2.5em 0em 0 0em rgba(68, 128, 247, 0.7), 1.75em 1.75em 0 0em #4480F7, 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.5), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.7), 0em 2.5em 0 0em #4480F7, -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.2), -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.5), 0em 2.5em 0 0em rgba(68, 128, 247, 0.7), -1.8em 1.8em 0 0em #4480F7, -2.6em 0em 0 0em rgba(68, 128, 247, 0.2), -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.5), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.7), -2.6em 0em 0 0em #4480F7, -1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(68, 128, 247, 0.2), 1.8em -1.8em 0 0em rgba(68, 128, 247, 0.2), 2.5em 0em 0 0em rgba(68, 128, 247, 0.2), 1.75em 1.75em 0 0em rgba(68, 128, 247, 0.2), 0em 2.5em 0 0em rgba(68, 128, 247, 0.2), -1.8em 1.8em 0 0em rgba(68, 128, 247, 0.5), -2.6em 0em 0 0em rgba(68, 128, 247, 0.7), -1.8em -1.8em 0 0em #4480F7;
  }
}

@keyframes show_up_mobile {
  0%{
    transform: translate3d(0px, 100%, 0px);
  }
  100%{
    transform: translate3d(0px, 0%, 0px);
  }
}